import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/global.scss';
import {Amplify, Auth} from 'aws-amplify';
import {createHttpLink} from '@apollo/client/link/http';
import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import {ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, } from '@apollo/client';
import styles from './style/App.module.scss';
import Router from './router';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

awsconfig.aws_user_pools_id = 'eu-west-1_9ttNR9DKU';
awsconfig.aws_user_pools_web_client_id = '1j805vkfrv4loaib3k1oqh8hiu';

Amplify.configure(awsconfig);

const url = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;
const authType = awsconfig.aws_appsync_authenticationType;

const auth = {
  type: authType, // 'AWS_IAM'
  credentials: () => Auth.currentCredentials(),
};

const httpLink = createHttpLink({uri: url});

const link = ApolloLink.from([
  createAuthLink({url, region, auth}),
  createSubscriptionHandshakeLink({url, region, auth}, httpLink),
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const AppShell = () => {
  return (
    <Authenticator
      loginMechanisms={['email']}
      initialState={'signIn'}
      hideSignUp={true}
    >
      {({user}) => {
        return (
          <ApolloProvider client={client}>
            <div className={styles.App}>
              <Router user={user} />
            </div>
          </ApolloProvider>
        );
      }}
    </Authenticator>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppShell />);
